.headerBtn {
  display: flex;
  align-items: center;
  gap: 13px;
}
/* 
.headerBottomContainer .container {
    max-width: 1700px !important;
    padding: 0 40px !important;
} */

.AirdropEmmet,
.EmmetTokken {
  color: #fff;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  text-transform: capitalize;
  white-space: nowrap;
  display: flex;
  align-items: center;
  padding: 7px 48px 7px 18px;
  position: relative;
  z-index: 1;
}

.AirdropEmmet img {
  position: absolute;
  z-index: 1;
  right: 3px;
  top: 50%;
  transform: translateY(-50%);
}

.EmmetTokken img {
  position: absolute;
  right: -6px;
  top: 40%;
  transform: translateY(-50%);
}

.AirdropEmmet::before,
.EmmetTokken::before {
  position: absolute;
  z-index: -2;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  display: block;
  content: "";
  border-radius: 28px;
  background: linear-gradient(
    115deg,
    #4a67ff -58.09%,
    #efeb00 -16.35%,
    #8940d3 29.96%,
    #4a67ff 70.39%
  );
}

.AirdropEmmet::after,
.EmmetTokken::after {
  position: absolute;
  z-index: -1;
  left: 1px;
  top: 1px;
  right: 1px;
  bottom: 1px;
  display: block;
  content: "";
  border-radius: 28px;
  background: #000;
  transition: 0.3s ease all;
}

.AirdropEmmet:hover::after {
  opacity: 0;
}

.EmmetTokken::after {
  opacity: 0;
}

.EmmetTokken:hover::after {
  opacity: 1;
}
