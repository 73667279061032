.align-center {
  text-align: center;
}

.borderLine {
  height: 2px;
  display: block;
}

.borderLine img {
  display: block;
  width: 100%;
  height: 100%;
}

@media screen and (min-width: 768px) {
  .tokMobImg {
    display: none !important;
  }
}

@media screen and (max-width: 1025px) {
  .introEmmetLeft h1 {
    font-size: 56px;
  }

  .introEmmetLeft p {
    font-size: 16px;
  }

  .introEmmetContent {
    padding-top: 20px;
    padding-bottom: 70px;
  }

  .distributionContainer {
    padding: 70px 0;
  }

  .tokenomicsBoxContainer {
    padding: 70px 0;
  }

  .tokenomicsBox h2 {
    font-size: 36px;
  }
}

@media screen and (max-width: 767px) {
  .tokDesImg {
    display: none !important;
  }

  .introEmmetContent {
    padding-bottom: 46px;
  }

  .introEmmetLeft {
    text-align: center;
    margin-bottom: 56px;
  }

  .introEmmetLeft p {
    margin: 16px 0 32px 0;
  }

  .introEmmetRight {
    margin: 0 auto;
  }

  .introEmmetContent {
    padding-top: 20px;
    padding-bottom: 56px;
  }

  .distributionContent img {
    max-width: 480px;
    margin: 0 auto;
  }

  .tokenomicsBox {
    max-width: 480px;
    margin: 0 auto;
    padding: 16px;
  }

  .tokenomicsBox h2 {
    font-size: 24px;
  }

  .tokenomicsBoxContainer .row {
    gap: 16px;
  }

  .tokenMetrics ul li {
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
  }

  .tokenMetrics ul li > div {
    text-align: left;
    width: 100%;
  }

  .distributionContainer {
    padding: 60px 0 30px 0;
  }

  .tokenomicsBoxContainer {
    padding: 30px 0px 90px 0;
  }
}
