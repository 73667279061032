.explorerTransactionsTitle h2 {
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 26px;
  color: #fff;
}

.explorerTransactionsTitle p {
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  color: #b3b7bf;
}

.explorerTransactions {
  padding-bottom: 24px;
  display: flex;
  align-items: center;
  padding-top: 24px;
}

.ExplorerTransactionsFilter {
  border-radius: 8px;
  border: 1px solid var(--stroke-stroke-primary, #3c3f43);
  padding: 10px;
  display: flex;
  align-items: center;
  gap: 10px;
}

.filterName {
  padding-right: 10px;
  border-right: 1px solid #3c3f43;
}

.transactionRight {
  display: flex;
  align-items: center;
  gap: 8px;
  margin-left: auto;
}

.filterDropSelect {
  display: flex;
  align-items: center;
  gap: 8px;
  cursor: pointer;
}

.filterDropSelect span {
  background: transparent !important;
  border: 0;
  outline: none;
  box-shadow: none;
  display: block;
  color: #fff !important;
  font-weight: 600;
  font-size: 14px;
  /* text-transform: capitalize; */
}

.filterDropdown {
  position: relative;
  z-index: 1;
}

ul.filterDropList {
  position: absolute;
  z-index: 1;
  top: 47px;
  right: -10px;
  border-radius: 8px;
  border: 1px solid var(--card-card-border, rgba(97, 100, 107, 0.1));
  background: var(--background-background-primary, #191b1e);
  /* Dropdown shadow */
  box-shadow: 0px 2px 12px 6px rgba(0, 0, 0, 0.16);
  padding: 16px;
  width: 180px;
  /* display: flex; */
  /* flex-direction: column; */
  /* gap: 14px; */
  cursor: pointer;
}

.allType {
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
}

ul.filterDropList li:not(:first-child) span {
  display: inline-flex;
  height: 28px;
  text-align: center;
  justify-content: center;
  align-items: center;
  width: 100%;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  border-radius: 6px;
}

.redeem {
  background: rgba(23, 85, 89, 0.2);
  color: #1fd8e4;
}

span.transfer {
  background: rgba(30, 86, 152, 0.2);
  color: #53b7ff;
}

span.borrow {
  background: rgba(112, 28, 141, 0.2);
  color: #d983fd;
}

span.deposit {
  color: #40d86e;
  background: rgba(146, 87, 33, 0.2);
}

span.approval {
  color: #40d86e;
  background: rgba(16, 93, 39, 0.2);
}

span.swap {
  color: #efeb00;
  background: #2d2d08;
}

ul.filterDropList li:not(:first-child) {
  margin-top: 14px;
}

.filterDropdown > .selectCoin {
  background: transparent;
  padding: 0;
  min-width: unset;
  border: 0;
}

.filterDropdown .selectCoinList {
  right: -10px;
  margin-top: 24px;
}

@media only screen and (max-width: 1025px) {
  .filterDropdownBox {
    position: relative;
    z-index: 1;
  }

  .transactionRight {
    /* position: absolute; */
    /* z-index: 111; */
    /* right:0; */
    /* top: 100%; */
    /* margin-top: 10px; */
    /* padding: 16px 16px 16px 16px; */
    /* background: #191B1E; */
    /* border: 1px solid rgba(97, 100, 107, 0.10); */
    /* border-radius: 10px 10px 0px 0px; */
    /* gap: 10px; */
    /* display: none; */
    /* flex-direction: column; */
    /* align-items: flex-start; */
  }

  .transactionRight.open {
    display: flex;
  }

  button.filterDropBTN {
    background: transparent;
    border: 0;
    outline: none;
    box-shadow: none;
    padding: 0;
  }

  button.filterDropBTNClose {
    outline: none;
    box-shadow: none;
    padding: 0;
    background: #000;
    border: 1px solid rgba(97, 100, 107, 0.1);
    display: flex;
    align-items: center;
    justify-content: center;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    position: absolute;
    right: 16px;
    top: 16px;
  }

  button.filterDropBTNClose img {
    filter: invert(1) brightness(10);
  }

  .ExplorerTransactionsFilter {
    width: 100%;
  }

  .explorerTransactions {
    align-items: flex-start;
    justify-content: space-between;
    gap: 50px;
    flex-direction: column;
    gap: 16px;
  }

  .ExplorerTransactionsFilter.tnxTypeFilter {
    position: relative;
    z-index: 3;
  }

  .ExplorerTransactionsFilter.fromTypeFilter {
    position: relative;
    z-index: 2;
  }

  .ExplorerTransactionsFilter.toTypeFilter {
    position: relative;
    z-index: 1;
  }

  .transactionRight .selectCoinList {
    position: absolute;
    top: 100%;
    z-index: 111;
    right: -30px;
    bottom: 0;
    left: auto !important;
    max-height: unset !important;
    min-height: fit-content;
    border-radius: 10px !important;
  }

  .ExplorerTransactionsFilter ul.filterDropList {
    right: -10px;
    top: 33px;
  }

  .transactionRight .selectCoinList {
    margin: 12px 0 0 0;
    right: -12px;
  }

  .transactionRight .filterDropdown {
    margin-left: auto;
  }

  .transactionRight {
    flex-direction: column;
    max-width: 480px;
    width: 100%;
    margin-right: auto;
    margin-left: 0;
  }

  .transactionRight .ExplorerTransactionsFilter {
    width: 100%;
  }

  .filterDropdownBox {
    width: 100%;
  }

  .explTransaTop .transactionRight {
    flex-direction: row;
  }

  .explTransaTop .transactionRight .ExplorerTransactionsFilter {
    width: calc(33.333% - 5px);
    display: block;
  }

  .explTransaTop .filterName {
    border: 0;
    padding: 0;
    margin-bottom: 5px;
  }

  .explTransaTop .ExplorerTransactionsFilter.tnxTypeFilter {
    order: 3;
    padding: 11px 10px;
  }

  .explTransaTop .coinNameIcon {
    gap: 5px;
  }

  .explTransaTop .selectedCoin {
    gap: 2px;
  }

  .explTransaTop .selectedCoin .coinNameIcon span {
    word-break: break-all;
    line-height: 1;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 1; /* number of lines to show */
    line-clamp: 1;
    -webkit-box-orient: vertical;
  }

  .explorerTransactions.explTransaTop {
    padding-top: 0;
  }
}

@media only screen and (max-width: 480px) {
  .transactionRight
    .ExplorerTransactionsFilter.fromTypeFilter
    ul.selectCoinList {
    left: -24px !important;
    right: auto !important;
  }
}
