.switchBtn {
  display: block;
  margin: -14px auto;
  background: transparent;
  border: 0;
  outline: none;
  box-shadow: none;
  padding: 0;
}

button.switchBtn {
  order: 2;
  position: relative;
  z-index: 2;
}
