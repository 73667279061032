.detialItem {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  color: #b3b7bf;
  padding: 12px 0;
  border-bottom: 1px solid rgba(97, 100, 107, 0.1);
}

.bridgeDetails {
  padding: 16px 0;
  margin-bottom: 30px;
}

.detialItemRight {
  display: flex;
  align-items: center;
  gap: 5px;
}
