.walletBalance {
  display: inline-flex;
  align-items: center;
  gap: 6px;
  max-width: 100%;
}

.walletBalance span:nth-child(2) {
  width: calc(100% - 55px);
  display: block;
  overflow: hidden;
  word-wrap: break-word;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 1; /* number of lines to show */
  line-clamp: 1;
  -webkit-box-orient: vertical;
}
