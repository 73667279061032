.notufyModal {
  position: fixed;
  z-index: 999;
  left: 0;
  top: 0;
  width: 100%;
  height: 100vh;
  background: rgb(21 21 22 / 80%);
  padding-top: 10%;
}

.notufyModalBox {
  padding: 16px 24px 24px;
  border-radius: 16px;
  box-shadow: 0 2px 12px 6px rgba(0, 0, 0, 0.16);
  border: solid 1px #3c3f43;
  background-color: #202226;
  width: 300px;
  margin: 0 auto;
}

.notifyTitle {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #3c3f43;
  font-size: 16px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  padding-bottom: 16px;
}

.notufyModalBody p {
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.63;
  letter-spacing: normal;
  color: #e0e3e6;
  text-align: center;
  margin: 0 0 16px 0;
}

.notufyModalBody {
  padding: 16px 0 0px 0;
}

.notufyModalBody form {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.notufyModalBody form input {
  padding: 12px 14px;
  border-radius: 8px;
  border: solid 1px #3c3f43;
  background: transparent;
  outline: none;
  box-shadow: none;
  color: #fff;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.57;
  letter-spacing: normal;
  text-align: left;
  animation-range: 48px;
}

.notufyModalBody form input::placeholder {
  color: #888b92;
}

.notufyModalBody form button {
  height: 44px;
  align-self: stretch;
  flex-grow: 0;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 8px;
  padding: 12px 18px;
  border-radius: 8px;
  border: solid 1.5px rgba(97, 100, 107, 0.3);
  background-color: rgba(148, 151, 158, 0.16);
  color: #b3b7bf;
  transition: 0.3s;
}

.notufyModalBody form button:hover {
  background: #efeb00;
  border-color: #efeb00;
  color: #191b1e;
}

.closeNot {
  display: block;
  cursor: pointer;
}
