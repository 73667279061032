.bridgeTransactionDetails {
  padding: 16px 0;
}

.bridgeTransactionDetailsList {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 12px 0;
  border-bottom: 1px solid #3c3f43;
}

.bridgeTransactionDetailsList > div {
  display: flex;
  align-items: center;
  gap: 5px;
}
