.AccessDefiContainer {
  padding: 80px 0;
}

.AccessDefi {
  position: relative;
  z-index: 1;
  padding: 94px 0px;
  border-radius: 16px;
  overflow: hidden;
}

img.defiBgDesk {
  display: block;
  position: absolute;
  z-index: -1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.AccessDefiInner {
  max-width: 640px;
  text-align: center;
  margin: 0 auto;
}

.AccessDefiInner h2 {
  text-align: center;
  font-family: Inter;
  font-size: 46px;
  font-style: normal;
  font-weight: 500;
  line-height: 140%; /* 64.4px */
  letter-spacing: -1.38px;
  color: #fff;
  margin-bottom: 23px;
}

.AccessDefiInner p {
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  max-width: 520px;
  margin: 0 auto;
  margin-bottom: 36px;
}

.whiteBtn {
  display: inline-flex;
  align-items: center;
  color: var(--Text-Text-on-yellow, #191b1e);
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px; /* 171.429% */
  text-transform: capitalize;
  border-radius: 8px;
  border: 1px solid var(--Text-Text-primary, #fff);
  background: var(--Text-Text-primary, #fff);
  padding: 9px 18px 9px 24px;
  gap: 4px;
  transition: 0.3s ease all;
}

img.DefiDeskArt-1 {
  display: block;
  position: absolute;
  z-index: -1;
  top: 0;
  left: 10%;
}

img.DefiDeskArt-2 {
  display: block;
  position: absolute;
  z-index: -1;
  right: -5px;
  bottom: -5px;
}

.DefiMobArt-1,
.DefiMobArt-2 {
  display: none;
}

@media only screen and (max-width: 767px) {
  .AccessDefi {
    padding: 76px 24px;
    max-width: 425px;
    margin: 0 auto;
  }

  .oneStopContainer {
    padding-bottom: 0;
  }

  .AccessDefiContainer {
    padding: 60px 0;
  }

  img.DefiDeskArt-1,
  img.DefiDeskArt-2 {
    display: none !important;
  }

  .AccessDefiInner h2 {
    font-size: 36px;
    font-style: normal;
    font-weight: 500;
    line-height: 120%; /* 43.2px */
    letter-spacing: -1.08px;
    margin-bottom: 16px;
  }

  .AccessDefiInner p {
    margin-bottom: 30px;
  }

  .DefiMobArt-1,
  .DefiMobArt-2 {
    display: block;
    pointer-events: none;
  }

  img.DefiMobArt-1 {
    display: block;
    position: absolute;
    z-index: -1;
    left: 0;
    top: 0;
  }

  img.DefiMobArt-2 {
    position: absolute;
    z-index: -1;
    right: 0;
    bottom: 0;
  }
}
