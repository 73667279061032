.label {
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: normal;
  text-align: left;
  color: #b3b7bf;
  margin: 0 0 10px 0;
}

p.label.right-text {
  color: #e0e3e6;
  text-align: right;
}

.emmetBuyColum {
  display: flex;
  gap: 10px;
}

.emmetSpentBox {
  width: 30%;
  flex-grow: 1;
}

.emmetBalance {
  width: 160px;
  flex-shrink: 0;
}

.emmetSpentBox {
}

.emmetSpentinput {
  display: flex;
  align-items: center;
}

.emmetSpentinput input {
  width: 90px;
  border: 0;
  outline: none;
  box-shadow: none;
  background: transparent;
  color: #fff;
  font-size: 18px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.44;
}

.emmetSpentinput input::placeholder {
  color: #fff;
}

.emmetSpentinput {
  gap: 6px;
  padding: 10px 12px 10px 16px;
  border-radius: 8px;
  border: solid 1px #3c3f43;
  display: flex;
  height: 50px;
  align-items: center;
}

.emmetSpentinput p {
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: normal;
  text-align: left;
  color: #b3b7bf;
  margin: 0 16px 0 auto;
}

.max {
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: normal;
  text-align: left;
  color: #e0e3e6;
  border: 0;
  padding: 1px 10px;
  background-color: #202226;
  display: inline-block;
}

.emmetBalanceDrop {
  padding: 12px 16px;
  border-radius: 8px;
  border: solid 1px #3c3f43;
}

.emmetBalanceDrop .coinNameIcon span {
  font-size: 14px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  text-transform: uppercase;
}

.emmetBalanceDrop .selectCoinList {
  right: -16px;
  width: auto !important;
  left: -16px;
  margin-top: 20px;
}

.emmetBalanceDrop .selectedCoin > img {
  width: 21px;
}

.emmetBalanceDrop .coinItem {
  padding-left: 0;
  padding-right: 0;
}

.receiveEmmet {
  padding: 7px 16px;
  border-radius: 8px;
  border: solid 1px #3c3f43;
  height: 50px;
  display: flex;
  align-items: center;
  gap: 16px;
}

.receiveEmmet > img {
  width: 24px;
}

.receiveEmmet > span {
  font-size: 16px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: left;
  color: #fff;
}

.iwantbuyEmmet {
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.emmetSpentinput h2 {
  font-size: 18px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.44;
  letter-spacing: normal;
  text-align: left;
  color: #fff;
}

button.connectWallet {
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;
  gap: 8px;
  padding: 12px 16px 12px 14px;
  border-radius: 8px;
  background-color: #efeb00;
  justify-content: center;
  color: #191b1e;
}

.buyTopBox.buyPreeSeed {
  position: relative;
  z-index: 1;
  border-color: transparent;
}

.buyTopBox.buyPreeSeed::before,
.buyTopBox.buyPreeSeed::after {
  position: absolute;
  z-index: -1;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  display: block;
  border-radius: 8px;
  content: "";
  background: linear-gradient(to bottom, #202226, #202226),
    linear-gradient(to bottom, #4d4d4d 0%, rgba(77, 77, 77, 0.41) 100%);
}

.buyTopBox.buyPreeSeed::after {
  z-index: -2;
  background: linear-gradient(
    115deg,
    #4a67ff -58.09%,
    #efeb00 -16.35%,
    #8940d3 29.96%,
    #4a67ff 70.39%
  );
  left: -1px;
  right: -1px;
  bottom: -1px;
  top: -1px;
}

@media screen and (max-width: 767px) {
  .max {
    display: none;
  }

  .emmetSpentinput p {
    margin-right: 0;
  }
}
