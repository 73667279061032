.privacyPolicy .container {
  max-width: 984px;
}

.privacyPolicy {
  padding: 20px 0 50px;
}

.privacyPolicyTitle {
  text-align: center;
  border-radius: 16px;
  border: 1px solid #4d4d4d;
  background: #1b1d20;
  padding: 64px 0;
  margin-bottom: 42px;
  position: relative;
  z-index: 1;
}

img.TitleBg {
  position: absolute;
  z-index: -1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.privacyPolicyTitle h1 {
  color: #fff;
  text-align: center;
  font-family: Inter;
  font-size: 46px;
  font-style: normal;
  font-weight: 500;
  line-height: 140%; /* 64.4px */
  letter-spacing: -1.38px;
  margin-bottom: 20px;
}

.privacyPolicyTitle p {
  color: #e0e3e6;
  text-align: center;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 110%; /* 17.6px */
  letter-spacing: -0.48px;
}

.privacyPolicyContent {
  color: #b3b7bf;
  font-feature-settings:
    "clig" off,
    "liga" off;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 26px; /* 162.5% */
}

.privacyPolicyContent b {
  display: block;
  color: #e0e3e6;
  font-feature-settings:
    "clig" off,
    "liga" off;
  font-family: Inter;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 26px;
}

.privacyPolicyContent b,
.privacyPolicyContent h3 {
  color: #fff;
  font-family: Inter;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 32px;
}

.privacyPolicyContent a {
  color: #53b7ff;
}

@media only screen and (max-width: 767px) {
  .privacyPolicyTitle {
    padding: 58px 0;
    border-radius: 16px;
    border: 1px solid #4d4d4d;
    background: #1b1d20;
  }

  .privacyPolicyTitle h1 {
    color: #fff;
    text-align: center;
    font-family: Inter;
    font-size: 36px;
    font-style: normal;
    font-weight: 500;
    line-height: 140%; /* 50.4px */
    letter-spacing: -1.08px;
  }
}
