ul.socialList {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 16px;
  gap: 24px;
  border-radius: 16px;
  border: 1px solid #3c3f43;
}

@media only screen and (max-width: 1025px) {
  ul.socialList {
    justify-content: unset;
    border-radius: 0;
    border: 0;
  }
}
