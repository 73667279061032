.headerNave {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 24px 0 18px;
}

.headerRightSide {
  display: flex;
  align-items: center;
  gap: 8px;
}

.connectWallet {
  border-radius: 8px;
  border: 1.5px solid rgba(97, 100, 107, 0.3);
  background: rgba(148, 151, 158, 0.16);
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 8px 12px 8px 8px;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: -0.3px;
  color: #e0e3e6;
  cursor: pointer;
}

.connectWalletModal {
  position: absolute;
  background: #000;
  border-radius: 10px;
  z-index: 101;
  top: 50%;
  left: 50%;
  right: auto;
  bottom: auto;
  margin-right: -50%;
  transform: translate(-50%, -50%);
  padding: 32px;
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.connectWalletModalOverlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 100;
  background-color: rgba(0, 0, 0, 0.3);
}

.alertModal {
  position: absolute;
  background: #000;
  border-radius: 10px;
  z-index: 101;
  top: 50%;
  left: 50%;
  right: auto;
  bottom: auto;
  margin-right: -50%;
  transform: translate(-50%, -50%);
  width: 350px;
  padding: 22px;
}

.alertModalOverlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 100;
  background-color: rgba(0, 0, 0, 0.3);
}

.alertModalHeader {
  display: flex;
  justify-content: space-between;
  margin-bottom: 16px;
  align-items: center;
}

.alertModalCloseBtn {
  border: none;
  font-size: 16px;
  background-color: black;
  margin-bottom: 10px;
  display: block;
  margin-left: auto;
  padding: 0;
}

.alertModalTitle {
  margin: 0;
  padding: 0;
}

.alertModalCloseBtn svg {
  width: 25px;
  height: 25px;
}

@media screen and (min-width: 1000px) {
  .connectWalletModal {
    left: calc(50% + 140px);
  }

  .alertModal {
    left: calc(50% + 140px);
  }
  :host {
    border: 10px solid red;
  }

  .go37342527.go1901766449 {
    position: relative;
    left: 140px;
  }
}
