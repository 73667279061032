.bridgeTransactionTop {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 24px;
}

.bridgeTransactionTop h2 {
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 32px;
}

img.backBtn {
  cursor: pointer;
}
