.inputAddress input {
  background: transparent;
  border: 0;
  outline: none;
  box-shadow: none;
  color: #fff;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
}

.inputAddress input {
  border-radius: 8px;
  border: 1px solid rgba(97, 100, 107, 0.1);
  background: #1b1d20;
  padding: 16px;
  display: flex;
  align-items: center;
  display: block;
  width: 100%;
}

.inputAddress input:focus {
  border-radius: 8px;
  border: 1px solid #61646b;
  background: #1b1d20;
}

.changeAddress {
  outline: none;
  box-shadow: none;
  background: transparent;
  padding: 0;
  border: 0;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: -0.3px;
  color: #53b7ff;
  position: absolute;
  right: 20px;
  top: 50%;
  transform: translateY(-50%);
}

.inputAddress {
  flex-grow: 1;
}

.wallet_Address {
  position: relative;
  z-index: 1;
}
