.skeleton {
  background: white;
  width: 100%;
  animation: skeleton-loading 1s linear infinite alternate;
  border-radius: 4px;
  display: inline-block;
}

@keyframes skeleton-loading {
  0% {
    opacity: 0.2;
  }
  100% {
    opacity: 0.4;
  }
}
